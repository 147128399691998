import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { StyledLink } from '../common/link'
import { Person } from './person'
import { device  } from '../../utils/breakpoints'




const StyledWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.grayWhite};
`

const StyledContainer = styled(Container) `
    gap: 2rem;
    justify-items: center;

 

`
const StyledHeading = styled(Heading) `
   
   g {
       font-weight: 500;
       color: ${({ theme }) => theme.colors.green};
   }

`

const ContainerTeam = styled.div `
    max-width: 1000px;
    display: grid;
    gap: 2rem;
    grid-template-columns: auto;
    justify-items: center;
`



export const Team = ({ lodz, bydgoszcz, torun }) => {
    const data = useStaticQuery(graphql`
    
    {
        allContentfulLodzZespol(sort: {fields: kolejnosc}) {
            nodes {
              id
              imie
              stanowisko
              opis {
                opis
              }
              zdjecie {
                gatsbyImageData(layout: CONSTRAINED)
                title
                description
              }
            }
        },

        allContentfulBydgoszczZespol(sort: {fields: kolejnosc}) {
            nodes {
              id
              imie
              stanowisko
              opis {
                opis
              }
              zdjecie {
                gatsbyImageData(layout: CONSTRAINED)
                title
                description
              }
            }
        },

        allContentfulTorunZespol(sort: {fields: kolejnosc}) {
            nodes {
              id
              imie
              stanowisko
              opis {
                opis
              }
              zdjecie {
                gatsbyImageData(layout: CONSTRAINED)
                title
                description
              }
            }
        }
    }
    `)

    const lodzZespol = data.allContentfulLodzZespol.nodes
    const bydgoszczZespol = data.allContentfulBydgoszczZespol.nodes
    const torunZespol = data.allContentfulTorunZespol.nodes


    if(lodz) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <StyledHeading>Poznaj <g>nasz zespół :)</g></StyledHeading>
                    <ContainerTeam>
                        {lodzZespol.map((person) => {
                            return (
                                <Person key={person.id} person={person}/>
                            )
                        })}
                    </ContainerTeam>
                </StyledContainer>
            </StyledWrapper>
        )
    } else if(bydgoszcz) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <StyledHeading>Poznaj <g>nasz zespół :)</g></StyledHeading>
                    <ContainerTeam>
                        {bydgoszczZespol.map((person) => {
                            return (
                                <Person key={person.id} person={person}/>
                            )
                        })}
                    </ContainerTeam>
                </StyledContainer>
            </StyledWrapper>
        )
    } else if(torun) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <StyledHeading>Poznaj <g>nasz zespół :)</g></StyledHeading>
                    <ContainerTeam>
                        {torunZespol.map((person) => {
                            return (
                                <Person key={person.id} person={person}/>
                            )
                        })}
                    </ContainerTeam>
                </StyledContainer>
            </StyledWrapper>
        )
    }

    
}