import React from 'react'
import styled from 'styled-components'

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { Comment } from './comment'

import { device  } from '../../utils/breakpoints'


import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'



import 'swiper/css'
import 'swiper/css/bundle'


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const StyledWrapper = styled(Wrapper) `


`

const StyledContainer = styled(Container) `

`

const StyledHeading = styled(Heading) `
    justify-self: center;
    span {
       color: ${({ theme }) => theme.colors.green};
    }
`

const ContainerComments = styled.div `

`

const StyledSwiper = styled(Swiper) `
    width: 100%;
    min-height: 400px;
    display: grid;
    justify-items: center;
    align-content: center;

    .swiper-button-prev:after,
    .swiper-button-next:after {
      color: #2D6A4F;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
    }

    
    @media ${device.tabletL} {
        .swiper-button-prev,
        .swiper-button-next {
            display: block !important;
        }
    }

    .swiper-pagination-bullet {
        background: #2D6A4F;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
    }
`

export const CustomerComments = () => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <StyledHeading h2 as='h2'>Co klienci mówią o <span>BodyMed Group</span>?</StyledHeading>

                <StyledSwiper
                navigation   
                pagination={{ clickable: true }}
                breakpoints={{
                    740: {
                    slidesPerView: 1,
                    },
                
                    1068: {
                    slidesPerView: 2,
                    },

                    1550: {
                        slidesPerView: 3,
                    },
                }}
                
                >
                    <SwiperSlide><Comment text="Wspaniała atmosfera i szeroki wybór zabiegów. Odwiedzam BodyMed Group regularnie i za każdym razem wychodzę zadowolona. Polecam z czystym sumieniem!"/></SwiperSlide>
                    <SwiperSlide><Comment text="Polecam serdecznie BodyMed Group. Uczęszczam regularnie na zabiegi Endermologii LPG Alliance oraz Dermapen 4.0. Efekty widoczne i jak najbardziej zadawalające, a przemiła atmosfera zachęca do powrotów!"/></SwiperSlide>
                    <SwiperSlide><Comment text="Miałam przyjemność poznać całą kadrę BodyMed Group. To naprawdę wspaniałe, życzliwe kobiety. Profesjonalizm, z jakim się spotkałam już na samym wejściu wprost nie do opisania. Jestem po kilku wizytach. Poleciłam BodyMed Group również moim znajomym, każda równie zachwycona :)"/></SwiperSlide>
                    <SwiperSlide><Comment text="Cieszę się, że w końcu udało mi się trafić do BodyMed Group! Bardzo dziękuję za profesjonalną opiekę oraz wyjątkową atmosferę, jaką zastaję tu za każdym razem. Korzystałam z zabiegów GeneO i polecam ten zabieg każdemu. Jestem zachwycona efektem, jaki udało nam się wspólnie uzyskać."/></SwiperSlide>
                </StyledSwiper>

            </StyledContainer>
        </StyledWrapper>
    )
}