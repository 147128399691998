import React from 'react'
import styled from 'styled-components'
import { ImQuotesLeft } from 'react-icons/im'
import { ImQuotesRight } from 'react-icons/im'


const ContainerComment = styled.div `
    position: relative;
    max-width: 400px;
    max-height: 300px;
    border-radius: 5px;
    padding: 2rem;
    display: grid;
    align-items: center;

    .top-left-icon {
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .bottom-right-icon {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
`

export const Comment = ({text}) => {

    return (
        <ContainerComment>
            <ImQuotesLeft size="35px" class="top-left-icon" color="#2D6A4F" />
                <p>{text}
                </p>
            <ImQuotesRight size="35px" class="bottom-right-icon" color="#2D6A4F" />
        </ContainerComment>
    )
} 