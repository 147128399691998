import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { StyledLink } from '../common/link'

import { device  } from '../../utils/breakpoints'




const StyledWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.white};
`

const StyledContainer = styled(Container) `
    gap: 2rem;
    justify-items: center;

    .image-styles { 
        border-radius: 5px;
    }

    .image {
        border-radius: 5px;

    }


    @media ${device.tabletL} {
        grid-template-columns: 1fr 1fr;
    }
`

const ContainerText = styled.div `
    display: grid;
    justify-items: center;
    align-content: start;
    gap: 2rem;

    b {
        color: ${({theme}) => theme.colors.green};
        font-size: 20px;
    }
`

const StyledHeading = styled(Heading) `
    color: ${({theme}) => theme.colors.green};
`   



export const AboutUs = ({ lodz, bydgoszcz, torun }) => {

    if(lodz) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <StaticImage
                        src="../../images/bodymedgroup-new-lodz.jpg"
                        alt="Zespół BodyMed Group w Lodzi" 
                        title="Zespół BodyMed Group" 
                        layout="constrained"
                        placeholder="dominantColor"
                        className="image"
                        imgClassName="image-styles"
                    />
                    <ContainerText>
                        <StyledHeading h1 as='h1'>Poznaj nas!</StyledHeading>
                        <p><b>BodyMed Group</b> to wyjątkowe miejsce na mapie Łodzi. 
                            Miejsce stworzone dla kobiet i mężczyzn, którzy pragną zadbać o swoje dobre samopoczucie, wymodelować sylwetkę oraz skorzystać z szerokiego wachlarzu zabiegów na twarz i ciało. 
                            Oferujemy zabiegi z zakresu kosmetologii estetycznej, laseroterapii, modelowania sylwetki oraz strefy fitness. Naszą misją jest sprawić, aby każdy mógł poczuć się u nas wyjątkowo. 
                            Zadbamy o wspaniałą atmosferę, podarujemy odrobinę relaksu i co najważniejsze, wykonamy wszelkie zabiegi na najwyższym poziomie.
                        </p>
                    </ContainerText>
                </StyledContainer>
            </StyledWrapper>
        )
    } else if(bydgoszcz) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <StaticImage
                        src="../../images/bodymedgroup-bydgoszcz.jpg"
                        alt="Zespół BodyMed Group w Bydgoszczy" 
                        title="Zespół BodyMed Group" 
                        layout="constrained"
                        placeholder="dominantColor"
                        className="image"
                        imgClassName="image-styles"
                    />
                    <ContainerText>
                        <StyledHeading h1 as='h1'>Poznaj nas!</StyledHeading>
                        <p><b>BodyMed Group</b> powstał z myślą o klientach świadomych i niezwykle wymagających. Oferuje nowoczesne i profesjonalne zabiegi z obszaru kosmetyki estetycznej i laserowej. 
                        Profesjonalny, doświadczony i empatyczny zespół kosmetologów wykonuje szereg usług w oparciu o najnowsze medyczne urządzenia, które pomogą w zachowaniu zdrowego i młodego wyglądu skóry, poprawią i wymodelują sylwetkę, usuną cellulit oraz zbędne niechciane owłosienie.
                        
                        </p>
                    </ContainerText>
                </StyledContainer>
            </StyledWrapper>
        )
    } else if(torun) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <StaticImage
                        src="../../images/bodymedgroup-torun.jpg"
                        alt="Zespół BodyMed Group w Bydgoszczy" 
                        title="Zespół BodyMed Group" 
                        layout="constrained"
                        placeholder="dominantColor"
                        className="image"
                        imgClassName="image-styles"
                    />
                    <ContainerText>
                        <StyledHeading h1 as='h1'>Poznaj nas!</StyledHeading>
                        <p><b>BodyMed Group</b> to wyjątkowe miejsce na mapie Torunia. 
                            Zostało stworzone z myślą o kobietach i mężczyznach, którzy pragną w przyjaznej i kameralnej atmosferze skorzystać z profesjonalnych, bezpiecznych i skutecznych rozwiązań z zakresu kosmetologii estetycznej i laseroterapii. 
                            W naszym gabinecie możecie skorzystać z bogatej oferty zabiegów, które poprawią kondycję i zdrowy wygląd skóry, wymodelują sylwetkę, usuną rozstępy, blizny czy niechciane owłosienie. 
                        </p>
                    </ContainerText>
                </StyledContainer>
            </StyledWrapper>
        )
    }
    
}