import React from 'react'
import styled from 'styled-components'

import { Heading } from '../common/heading'
import { Button } from '../common/button'

import { device } from '../../utils/breakpoints'

import { GatsbyImage } from "gatsby-plugin-image"

import { AiFillHeart } from 'react-icons/ai'

const ContainerTreatmentItem = styled.article `
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    gap: 1.5rem;
    padding: 1.5rem;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    width: 100%;
    box-shadow: rgb(0 0 0 / 3%) 20px 20px 60px;

    @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }


`

const ContainerImage = styled.div `
    height: 100%;
    width: 100%;
    max-height: 550px;


    .image-styles-hero {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        -webkit-border-top-left-radius: 10px;
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-top-left: 10px;
        -moz-border-radius-top-right: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
`

const Img = styled(GatsbyImage) `
    height: 100%;
    width: 100%;
`

const ContainerText = styled.div `
    display: grid;
    align-content: start;
    gap: 1rem;

    p {
        font-size: ${({ theme }) => theme.fonts.size.xs};
    }

    span {
        color: ${({ theme }) => theme.colors.green};
    }
    
`

const StyledHeading = styled(Heading) `
    color: ${({ theme }) => theme.colors.green};
    font-weight: 500;
`






export const Person = ({ person }) => {

    const {
        imie, stanowisko, opis, zdjecie
    } = person

    return (
        <ContainerTreatmentItem>
            <ContainerImage>
                <Img imgClassName="image-styles-hero" title={zdjecie?.title} alt={zdjecie?.description} image={zdjecie?.gatsbyImageData} />
            </ContainerImage>
            <ContainerText>
                <StyledHeading h3 as='h3'>{imie}</StyledHeading>
                <span>{stanowisko} <AiFillHeart className='icon-person' color='#40916C' size='20'/></span>
                <p>{opis.opis}</p>
            </ContainerText>
        </ContainerTreatmentItem>
    )
}