import React from 'react'

import Layout from '../components/layout'
import { AboutUs } from '../components/aboutUs'
import { Team } from '../components/team'
import { Newsletter } from '../components/common/newsletter' 
import { CustomerComments } from '../components/customerComments'
import Seo from '../components/seo'



const LodzAboutUs = () => (
  <Layout lodz>
    <Seo
      title="O nas - klinika modelowania sylwetki i kosmetologii estetycznej"
      description="Wyjątkowe miejsce na beauty mapie Łodzi. Sprawdź ofertę zabiegów zabiegów w klinice BodyMed Group Łódź." 
      url="https://bodymedgroup.pl/lodz-o-nas"
    />
    <AboutUs lodz/>
    <Newsletter />
    <CustomerComments />
  </Layout>
)

export default LodzAboutUs
