import React from 'react'
import styled from 'styled-components'

import { Wrapper } from '../wrapper'
import { Container } from '../container'
import { Heading } from '../heading'

import { MdOutlineMailOutline } from 'react-icons/md'

import { NewsletterForm} from '../formNewsletter'

const StyledWrapper = styled(Wrapper) `


`
const StyledContainer = styled(Container) `
    border-radius: 5px;
    position: relative;
    grid-template-rows: repeat(2, auto);
    gap: 2rem;
    background: ${({ theme }) => theme.colors.green};
    justify-items: center;
    align-content: start;
    padding: 3rem 1.5rem;

    .icon-newsletter {
        position: absolute;
        opacity: 7%;
        left: 50%;
        transform: translateX(-50%);
        top: 0%;
    }
`

const ContainerText = styled.div `
    display: grid; 
    justify-items: center;
    z-index: 1;

    p {
        color: ${({ theme }) => theme.colors.white};
    }

`

const StyledHeading = styled(Heading) `
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
`



export const Newsletter = () => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <MdOutlineMailOutline className='icon-newsletter' color='#fff' size='300'/>
                <ContainerText>
                    <StyledHeading h2 as='h2'> Pozostańmy w kontakcie!</StyledHeading>
                    <p>Zapisz się do naszego newslettera i bądź na bieżąco z naszymi promocjami.</p>
                </ContainerText>
                <NewsletterForm/>
            </StyledContainer>
        
        </StyledWrapper>
    )
}